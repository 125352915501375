import { assertNonNullish } from '.'

export const capitalizeFirstLetterOfEachWord = (text: string): string => {
  if (text && text.length > 0) {
    return text
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
  }
  return text
}

export const formatDateAsYMD = (date: Date): string => {
  assertNonNullish(date, 'formatDateAsYMD', 'date')
  return date.toISOString().split('T')[0]
}

export const yearForCopyright = (): string => {
  const now = new Date()
  return `${now.getUTCFullYear()}`
}

export const formatTimestampAsKey = (date: Date): string => {
  assertNonNullish(date, 'formatTimestampAsKey', 'date')
  return date.toISOString().replace(/[-.:]/g, '')
}

export const formatTimestampAsProtocol = (date: Date): string => {
  assertNonNullish(date, 'formatTimestampAsKey', 'date')
  return date.toISOString().replace(/[-.:TZ]/g, '')
}
