import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FirebaseInitializer } from '@cogniapp/common-typescript-frontend-lib';
import { LanguageService, LocalStorageService, DeviceService, ConfigService, createTranslateHttpLoader, AnalyticsService } from './infrastructure-layer';
import { environment } from '../environments/environment';
import { PlatformsEnum } from './domain-layer';


const initializeApp = (
  deviceService: DeviceService,
  firebaseInitializer: FirebaseInitializer,
  analyticsService: AnalyticsService,
  localStorageService: LocalStorageService,
  languageService: LanguageService
) => async () => {
  ConfigService.loadConfig(environment);
  await deviceService.initializeDeviceInfo(ConfigService.isProduction());
  firebaseInitializer.initializeInstances(ConfigService.getFirebase(PlatformsEnum.OTHER), false);
  analyticsService.initializeAnalytics(ConfigService.getAnalyticsConfig(PlatformsEnum.OTHER));
  await localStorageService.initializeStorage();
  await languageService.setInitialLanguage();
};

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateHttpLoader(),
        deps: [HttpClient]
      }
    }),
    IonicModule.forRoot(), 
    IonicStorageModule.forRoot({
      storeName: 'cognisite'
    }),
    AppRoutingModule
  ],
  providers: [
    FirebaseInitializer,
    AnalyticsService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: APP_INITIALIZER, deps: [DeviceService, FirebaseInitializer, AnalyticsService, LocalStorageService, LanguageService], useFactory: initializeApp, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
