import { Inject, Injectable } from '@angular/core';    
import { DOCUMENT } from '@angular/common';  
import { Device } from '@capacitor/device';
import { TranslateService } from '@ngx-translate/core';
import { supportedLocales } from '../../../app-wide-helpers'
import { LanguageCodesEnum } from 'src/app/domain-layer';
import { LocalStorageService } from '../local-storage.service';
import { SupportedLocale } from './supported-locale.type';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private static _currentLanguage: SupportedLocale;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService) {

    const langs = [];
    supportedLocales.forEach(language => {
      langs.push(language.code);
    });
    this.translateService.addLangs(langs);
  }

  /**
   * Returns current language which was not necessarily chosen by the user.
   */
  get currentLanguage(): LanguageCodesEnum {
    if (LanguageService._currentLanguage) {
      return LanguageService._currentLanguage.code;
    }
    return this.translateService.currentLang as LanguageCodesEnum;
  }

  /**
   * Returns the language chosen by the user.
   */
  get languageChosenByUser(): LanguageCodesEnum | undefined {
    if (LanguageService._currentLanguage) {
      return LanguageService._currentLanguage.code;
    }
    return undefined;
  }

  translate(key: string) {
    return this.translateService.instant(key);
  }

  async setLanguage(languageCode: string) {
    const supportedLanguage = this.getSupportedLanguage(languageCode);
    if (supportedLanguage) {
      LanguageService._currentLanguage = supportedLanguage;
      await this.localStorageService.saveLanguageValue(LanguageService._currentLanguage.code);
      this.translateService.setDefaultLang(LanguageService._currentLanguage.code);
      this.translateService.use(LanguageService._currentLanguage.code);
      this.document.documentElement.lang = LanguageService._currentLanguage.code.split('-')[0]; 
      return LanguageService._currentLanguage.code;
    }
    return this.currentLanguage;
  }

  async setInitialLanguage()  {
    const languageFromStorage = await this.localStorageService.readLanguageValue();
    let supportedLanguage = this.getSupportedLanguage(languageFromStorage);
    let languageCode: string;
    if (supportedLanguage) {
      languageCode = supportedLanguage.code;
      LanguageService._currentLanguage = supportedLanguage;
    } else {
      const languageFromDevice = (await Device.getLanguageCode()).value;
      supportedLanguage = this.getSupportedLanguage(languageFromDevice);

      if(!supportedLanguage) {
        supportedLanguage = this.getSupportedLanguage(this.translateService.getBrowserCultureLang());
      }

      if (supportedLanguage) {
        languageCode = supportedLanguage.code;
      } else {
        languageCode = 'pt-BR';
      }
    }
    this.translateService.setDefaultLang(languageCode);
    this.translateService.use(languageCode);
    this.document.documentElement.lang = languageCode.split('-')[0]; 
  }

  private getSupportedLanguage(code: string): any {
    const result = supportedLocales.find(language => language.code === code);
    if (result) {
      return result;
    }
    if (code && code.length >= 2) {
      const shorterCode = code.slice(0, 2);
      return supportedLocales.find(language => language.defaultFor === shorterCode);
    }
    return undefined;
  }
}
