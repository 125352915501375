import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

export class AnalyticsService {

  initializeAnalytics(firebaseAnalyticsConfig: any) {
    const app = initializeApp(firebaseAnalyticsConfig);
    getAnalytics(app);
  }
}
