import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(private storage: Storage) { }

  initializeStorage() {
    return this.storage.create();
  }

  saveLanguageValue(language: string) {
    return this.saveStringValue(this.languageKey, language);
  }
  readLanguageValue() {
    return this.readStringValue(this.languageKey);
  }

  private saveStringValue(key: string, value: string) {
    return this.write(key, value);
  }

  private readStringValue(key: string): Promise<string> {
    return this.read(key);
  }

  private read(key: string) {
    return this.storage.get(key);
  }

  private write(key: string, value: any) {
    return this.storage.set(key, value);
  }

  private languageKey = 'language';
}

