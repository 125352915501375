import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { LanguageService } from './infrastructure-layer';
import { NavController } from '@ionic/angular';
import { routes } from './app-wide-helpers';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  constructor(
    private location: Location, 
    private languageService: LanguageService,
    private navController: NavController) {
    if (this.location.path() === '') {
      const language = this.languageService.currentLanguage;
      this.navController.navigateRoot([`/${language}/${routes[language].professional}`]);
    }
  }
}
