import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';

const routes: Routes = [
  {
    path: 'en',
    loadChildren: () => import('src/app/features/app-en/app-en-page.module').then( m => m.AppEnPageModule)
  },
  {
    path: 'en-US',
    loadChildren: () => import('src/app/features/app-en/app-en-page.module').then( m => m.AppEnPageModule)
  },
  {
    path: 'pt',
    loadChildren: () => import('src/app/features/app-pt/app-pt-page.module').then( m => m.AppPtPageModule)
  },
  {
    path: 'pt-BR',
    loadChildren: () => import('src/app/features/app-pt/app-pt-page.module').then( m => m.AppPtPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('src/app/features/privacy-policy/privacy-policy-page.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'cc',
    loadChildren: () => import('src/app/features/invitation-howto-page/invitation-howto-page.module').then( m => m.InvitationPageModule)
  },
  {
    path: 'deletion',
    loadChildren: () => import('src/app/features/app-deletion-request-page/app-deletion-request-page.module').then( m => m.AppDeletionRequestPageModule)
  },
  {
    path: '',
    component: AppComponent
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
