// lowercase to match OS/browser locales
export enum LanguageCodesEnum {
  PT_BR = 'pt-BR',
  EN_US = 'en-US',
  /**
   * For compatibility only. DO NOT use for new users.
   */
  UNKNOWN = 'unknown'
}

export enum PlatformsEnum {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  OTHER = 'OTHER'
}

// lowercase for historical reasons
export enum ProductsEnum {
  COGNI_APP = 'cogni-app',
  COGNI_DASHBOARD = 'cogni-dashboard',
  COGNI_CORP_DASHBOARD = 'cogni-corp-dashboard',
  WEBSITE = 'website'
}
