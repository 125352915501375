import { ErrorsEnum } from "src/app/domain-layer"

export type ErrorDescription = {
  error?: ErrorsEnum
  message?: string
  params?: string[]
}

const throwError = (error: ErrorDescription) => {
  throw new Error(JSON.stringify({ errorDescription: error }))
}

export { throwError }
