export const routes = {
  'pt-BR': {
    patient: 'paciente',
    professional: 'profissional',
    privacy: 'privacidade',
    share: 'compartilhar',
    deletion: 'exclusao-de-conta',
  },
  'en-US': {
    patient: 'patient',
    professional: 'professional',
    privacy: 'privacy',
    share: 'share',
    deletion: 'account-deletion',
  }
};

export const pageTitles = {
  'pt-BR': {
    patient: 'Cogni - Aprenda a sentir-se bem!',
    professional: 'Cogni - Painel Clínico',
    privacy: 'Cogni - Política de Privacidade',
    share: 'Cogni - Compartilhamento de Cognis',
    deletion: 'Cogni - Exclusão de Conta',
  },
  'en-US': {
    patient: 'Cogni - Learn to feel better!',
    professional: 'Cogni - Clinical Dashboard',
    privacy: 'Cogni - Privacy Policy',
    deletion: 'Cogni - Account Deletion',
  }
};
