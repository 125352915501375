import { ErrorsEnum } from 'src/app/domain-layer'
import { throwError } from '.'

export function assertNonNullish<TValue>(
  value: TValue,
  functionName?: string,
  paramName?: string
): asserts value is NonNullable<TValue> {
  if (value === null || value === undefined) {
    if (functionName) {
      throwError({
        error: ErrorsEnum.NULLISH_PARAMETER,
        message: `${functionName} - ${paramName} is nullish`
      })
    }
    throw new Error(ErrorsEnum.NULLISH_PARAMETER)
  }
}
