import { LanguageCodesEnum } from '../domain-layer';
import { SupportedLocale } from '../infrastructure-layer/services/localization/supported-locale.type';

export const supportedLocales: SupportedLocale[] = [
  {
    code: LanguageCodesEnum.EN_US,
    defaultFor: 'en',
    name: 'SETTINGS.LANGUAGES.ENGLISH_US'
  },
  {
    code: LanguageCodesEnum.PT_BR,
    defaultFor: 'pt',
    name: 'SETTINGS.LANGUAGES.PORTUGUESE_BR',
  }
];
