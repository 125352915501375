export type DeviceInfo = {
  deviceUuid: string
  updatedAtDate: Date
  platform: string
  os: string
  osVersion: string
  webViewVersion: string
  model: string
  languageCode: string
  diskFree?: number
  appVersion?: string
  appBuild?: string
  uid?: string
}
