import { PlatformsEnum } from 'src/app/domain-layer';

export class ConfigService {

  private static environmentConfig: any;

  static loadConfig(environmentConfig: any) {
    ConfigService.environmentConfig = environmentConfig;
  }

  static getConfig(id: string): any {
    return ConfigService.environmentConfig[id];
  }

  static getAnalyticsConfig(platform?: PlatformsEnum) {
    return ConfigService.environmentConfig.firebase.analytics;
  }

  static getFirebase(platform?: PlatformsEnum) {
    return ConfigService.environmentConfig.firebase;
  }

  static isProduction(): boolean {
    return ConfigService.environmentConfig.production;
  }
}
