import { Injectable } from '@angular/core';
import { CommonFirestoreClient, FirebaseInitializer } from '@cogniapp/common-typescript-frontend-lib';
import { CollectionReference } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class FirestoreDatabaseService extends CommonFirestoreClient {
  
  constructor(firebaseInitializer: FirebaseInitializer) {
    super(firebaseInitializer, 'default')
  }

  insertEndUserSupportTicket(uid: string, key: string, attributes: any) {
    return this.insertDoc(this.getEndUserTicketsCollRef(uid), key, attributes);
  }

  getIdForNewReceipt() {
    return this.newDocId(this.getPixProofCollRef());
  }

  insertPixProof(key: string, attributes: any) {
    return this.insertDoc(this.getPixProofCollRef(), key, attributes);
  }

  protected getPixProofCollRef(): CollectionReference {
    return this.collRef(`${this.pixProofCollId}`)
  }

  protected getEndUserTicketsCollRef(uid: string): CollectionReference {
    return this.collRef(`${this.supportCollId}/${uid}/${this.ticketsCollGroupId}`)
  }

  protected supportCollId = '/support'
  protected ticketsCollGroupId = 'tickets'

  protected pixProofCollId = '/pixProofs'
}
