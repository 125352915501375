// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  message: 'DEV Environment',
  firebase: {
    defaultAppName: 'default',
    instances: {
      default: {
        apiKey: 'AIzaSyA9MHtCklUe8XVnwgP-M69msUkL84FRXSs',
        authDomain: 'cogni-ionic-dev.firebaseapp.com',
        databaseURL: 'https://cogni-ionic-dev.firebaseio.com',
        projectId: 'cogni-ionic-dev',
        storageBucket: 'cogni-ionic-dev.appspot.com',
        messagingSenderId: '1037140758256',
        appId: '1:1037140758256:web:d167d14359c576459b7c35',
        measurementId: 'G-FJY5ERWERY'
      }
    },
    analytics: {
      apiKey: 'AIzaSyA9MHtCklUe8XVnwgP-M69msUkL84FRXSs',
      authDomain: 'cogni-ionic-dev.firebaseapp.com',
      databaseURL: 'https://cogni-ionic-dev.firebaseio.com',
      projectId: 'cogni-ionic-dev',
      storageBucket: 'cogni-ionic-dev.appspot.com',
      messagingSenderId: '1037140758256',
      appId: '1:1037140758256:web:d167d14359c576459b7c35',
      measurementId: 'G-FJY5ERWERY'
    }
  },
  urls: {
    privacyPolicy: 'https://cogni-ionic-dev-static-content.web.app/privacy-policy/',
  },
  typesense: {
    nodes: [{
      host: 'search.cogniapp.com',
      port: 8109,
      protocol: 'https'
    }],
    apiKey: 'OHmiHROjbJsB0CvkvMIX0xvzv5wVnCT4',
    connectionTimeoutSeconds: 2
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
