import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { DeviceInfo, PlatformsEnum } from 'src/app/domain-layer';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  #device: DeviceInfo;

  constructor(private platform: Platform) {}

  async initializeDeviceInfo(isProduction: boolean) {
    await this.platform.ready();
    const uuid = await Device.getId();
    const info = await Device.getInfo();
    const languageCode = await Device.getLanguageCode();
    this.#device = {
      deviceUuid: uuid.identifier,
      languageCode: languageCode.value,
      model: info.model,
      os: info.operatingSystem,
      osVersion: info.osVersion,
      platform: this.devicePlatform,
      updatedAtDate: new Date(),
      webViewVersion: info.webViewVersion || null,
      diskFree: info.realDiskFree || null
    };
    try {
      const app = await App.getInfo();
      this.#device.appVersion = app.version;
      this.#device.appBuild = app.build;
      if (!isProduction) {
        this.#device.appVersion += '-dev';
      }
    }
    catch (error) {
      this.#device.appVersion = 'webversion';
      this.#device.appBuild = '0';
      if (!isProduction) {
        this.#device.appVersion += '-dev';
      }
    }
  }

  get device() {
    return this.#device;
  }

  get deviceUuid() {
    return this.#device.deviceUuid;
  }

  get deviceLanguage() {
    return this.#device.languageCode;
  }

  get appVersion() {
    return this.#device.appVersion;
  }

  get isCapacitor(): boolean {
    return this.platform.is('capacitor');
  }

  get isAndroid(): boolean {
    return this.#device.platform === PlatformsEnum.ANDROID;
  }

  get isIOs(): boolean {
    return this.#device.platform === PlatformsEnum.IOS;
  }

  get isCapacitorAndroid(): boolean {
    return this.platform.is('capacitor') && this.#device.platform === PlatformsEnum.ANDROID;
  }

  get isCapacitorIOs(): boolean {
    return this.platform.is('capacitor') && this.#device.platform === PlatformsEnum.IOS;
  }

  get devicePlatform(): PlatformsEnum {
    if(this.platform.is('android')) {
      return PlatformsEnum.ANDROID;
    }
    if(this.platform.is('ios')){
      return PlatformsEnum.IOS;
    }
    return PlatformsEnum.OTHER;
  }
}
